// extracted by mini-css-extract-plugin
export var caseContactFormSection = "b1_k4";
export var caseStudyBackground = "b1_nJ";
export var caseStudyBackground__lineColor = "b1_kW";
export var caseStudyCta__bgLight = "b1_nK";
export var caseStudyHead = "b1_k6";
export var caseStudyHead__imageWrapper = "b1_kT";
export var caseStudyProjectsSection = "b1_k5";
export var caseStudyQuote__bgRing = "b1_k1";
export var caseStudySolution__ring = "b1_kZ";
export var caseStudySolution__ringBottom = "b1_nL";
export var caseStudySolution__ringRight = "b1_nM";
export var caseStudyTechnology__bg = "b1_nN";
export var caseStudy__bgDark = "b1_kS";
export var caseStudy__bgLight = "b1_kR";